import { render, staticRenderFns } from "./NoSchool.vue?vue&type=template&id=2ccb1618&scoped=true"
import script from "./NoSchool.vue?vue&type=script&lang=js"
export * from "./NoSchool.vue?vue&type=script&lang=js"
import style0 from "./NoSchool.vue?vue&type=style&index=0&id=2ccb1618&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccb1618",
  null
  
)

export default component.exports