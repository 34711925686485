<template>
  <div class="dialog no-school pb-0">
    <b-card no-body class="card-heading">
      <b-tabs card fill v-model="tabIndex" content-class="tab-contents">
        <b-tab title="Add a School"
               :title-link-class="linkClass(0)"
               active>
          <add-school :cancelable="false"
                      :showDialogHeader="false"
                      @cancel-add-school="cancelModal"
                      @successful="closeModal"
                      ref="addSchool">
          </add-school>
        </b-tab>
        <b-tab title="Pick a School"
               :title-link-class="linkClass(1)">
          <select-school :cancelable="false"
                         :showDialogHeader="false"
                         @cancel-select-school="cancelModal"
                         @successful="closeModal"
                         ref="selectSchool">
          </select-school>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  const AddSchool = () => import('@/components/modals/AddSchool.vue');
  const SelectSchool = () => import('@/components/modals/SelectSchool.vue');

  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name   : 'NoSchool',
    mixins : [
      BreakpointMixin,
    ],
    data () {
      return {
        tabIndex : 0,
      }
    },
    components : {
      AddSchool,
      SelectSchool,
    },
    watch : {
      tabIndex(value) {
        if (value === 0)
          this.$refs.selectSchool.resetSelectedSchool();
        
        if (value === 1)
          this.$refs.addSchool.resetAddSchool();
      },
    },
    methods : {

      /**
       * Cancel Modal
       */
      cancelModal() {
        if (window.history.length > 2)
          this.$router.go(-1);
        else {
          this.$emit('close');
          this.$router.push({ path : '/dashboard' });

          // this.$router.push({ path : '/browse/main' });
        }
      },

      /**
       * Close Modal
       */
      closeModal() {
        this.$emit('close');
      },

      /**
       * Link Styling
       * @param id
       */
      linkClass(id) {
        if (this.tabIndex === id)
          return [ 'text-primary', 'active-tab' ];
        
        return ['text-light'];
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/no-school";
</style>